<template>
    <div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column label="标题" align="center" prop="title" min-width="300">
            </el-table-column>
            <el-table-column prop="typeCode" align="center" min-width="200" label="Code值">
            </el-table-column>
            <el-table-column prop="createTime" align="center" width="200" label="创建时间">
            </el-table-column>
            <el-table-column prop="updateTime" align="center" width="200" label="更新时间">
            </el-table-column>

            <el-table-column prop="icon" align="center" width="360" fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="UpdateCommonExplain(scope.row.typeCode)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <UpdateCommonExplain ref="updateCommonExplain" v-if="UpdateCommonExplainvisible"
            @refreshDataList="getCommonExplain"></UpdateCommonExplain>
    </div>
</template>

<script>
import UpdateCommonExplain from "./UpdateCommonExplain";
export default {
    name: "CommonExplain",
    components: {
        UpdateCommonExplain
    },
    data() {
        return {
            delBtlStatu: true,
            tableData: [],
            multipleSelection: [],
            UpdateCommonExplainvisible: false,
        }
    },
    created() {
        this.getCommonExplain();
    },
    methods: {
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.delBtlStatu = val.length == 0
        },
        //获取系统文字说明列表
        getCommonExplain() {
            this.$axios.post("/admin/commonExplain/getCommonExplainList").then(res => {
                this.tableData = res.data
            })
        },
        UpdateCommonExplain(typeCode) {
            console.log(typeCode)
            this.UpdateCommonExplainvisible = true;
            this.$nextTick(() => {
                this.$refs.updateCommonExplain.init(typeCode);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>
